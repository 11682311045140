<template>
	<div>
		<v-card>
			<v-data-table :headers="headers" :options.sync="options" :items="lista" :items-per-page="itemsPerPage"
				:loading="loadingTable" :key="`list-rol-invoices-${tenantId}`" hide-default-footer class="elevation-1">
				<template v-slot:top>
					<ExpandableFilters classButton='py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0'
						iconButtom="mdi-upload" classTitle="col-0" :filters=filters @datafilters=cargarLista(true) />
				</template>
				<template v-slot:item.opcion="{ item }">
					<v-btn icon color="red" @click="formInvoice(item.invoice_id)">
						<v-icon color="warning">mdi-pencil</v-icon>
					</v-btn>
					<!-- <v-btn
						icon
						color="red"
						@click="actualizaEstadoInvoice(item.invoice_id)"
					>
						<v-icon color="red">mdi-delete-forever</v-icon>
					</v-btn> -->
				</template>
				<template v-slot:item.total="{ item }">
					${{ parseFloat(item.total.replace(',', '')).toFixed(2) }}
				</template>
				<template v-slot:item.saldo="{ item }">
					${{ parseFloat(item.total.replace(',', '') - item.pagado).toFixed(2) }}
				</template>
				<template v-slot:footer>
					<v-pagination class="mt-10" v-model="currentPage" :length="pageCount" @input="handlePageChange"
						total-visible="10"></v-pagination>
				</template>
			</v-data-table>
		</v-card>
		<v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
			<v-card>
				<v-toolbar dark small color="primary">
					<v-btn icon dark @click="close">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title v-if="cabecera.invoice_type_id == 'PAYROL_INVOICE'">
						{{ cabecera.tipo_factura }}# {{ cabecera.invoice_id }}
					</v-toolbar-title>
					<v-toolbar-title v-if="cabecera.invoice_type_id != 'PAYROL_INVOICE'">
						{{ cabecera.tipo_factura }}#
						{{
							(cabecera.codigo_establecimiento != null
								? cabecera.codigo_establecimiento + "-"
								: "") +
							(cabecera.codigo_punto_emision != null
								? cabecera.codigo_punto_emision + "-"
								: "") +
							(cabecera.invoice_number != null ? cabecera.invoice_number : "")
						}}
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn dark text @click="close"> Salir </v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card class="pl-6 pr-6 pt-6">
					<v-row>
						<v-col cols=12 md="6" class="pt-2 pb-0">
							<v-alert dense text icon="mdi-file-chart" light color="blue-grey darken-2">
								Datos de la facutra
							</v-alert>
							<v-row>
								<v-col cols=12><b>DESCRIPCIÓN:</b> {{ cabecera.description }}</v-col>
								<v-col cols=12 sm="7" class="pt-0 pb-0">
									<v-text-field label="Empleado" required type="text" small readonly
										v-model="cabecera.participante"></v-text-field>
								</v-col>
								<v-col cols=4 sm="2" class="pt-0 pb-0">
									<v-text-field label="Estado*" required type="text" small readonly
										v-model="cabecera.estado"></v-text-field>
								</v-col>
								<v-col cols=8 sm="3" class="pt-0 pb-0">
									<v-text-field label="Fecha de emisión*" required type="date" small
										:readonly="updateHeader" v-model="cabecera.inv_fecha"></v-text-field>
								</v-col>
								<v-col>
									<v-form ref="form_cabecera">
										<v-row>
											<v-col cols=12 md="2" class="pt-0">
												<v-text-field v-model="numeroFactura.establecimiento"
													label="Establecimiento*" small counter="3" v-mask="'###'"
													:rules="required" :disabled="updateHeader"></v-text-field>
											</v-col>
											<v-col sm="12" md="2" class="pt-0">
												<v-text-field v-model="numeroFactura.ptoEmision" label="Pto. Emisión*" small
													:rules="required" v-mask="'###'" counter="3"
													:disabled="updateHeader"></v-text-field>
											</v-col>
											<v-col sm="12" md="8" class="pt-0">
												<v-text-field v-model="numeroFactura.numero" label="Número*"
													:rules="required" small :disabled="updateHeader"></v-text-field>
											</v-col>
											<v-col sm="12" md="12" class="pt-0">
												<v-text-field v-model="numeroFactura.claveAcceso"
													label="Clave de autorización*" :rules="required" small
													:disabled="updateHeader" append-icon="mdi-content-save"
													@click:append="actualizarCabecera(cabecera.invoice_id)"></v-text-field>
											</v-col>
										</v-row>
									</v-form>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols=12 md="6" class="pt-2">
							<v-alert dense text icon="mdi-cash-multiple" light color="blue-grey darken-2">
								Pagos de la factura
								<div style="float:right"><b>Saldo ${{ saldo }}</b></div>
							</v-alert>
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">
												ID
											</th>
											<th class="text-left">
												Tipo
											</th>
											<th class="text-left">
												Referencia
											</th>
											<th class="text-left">
												Fecha
											</th>
											<th class="text-left">
												Monto
											</th>
										</tr>
									</thead>
									<tbody v-if="pagos.length > 0">
										<tr v-for="(pago, p) in pagos" :key=p>
											<td>
												<v-btn v-if="pago.payment_type_id == 'RETENCION_EMITIDA'" small text
													target="_blank" :to="`retencion-emitida/${pago.payment_id}`">
													<v-icon left>mdi-eye</v-icon>
													{{ pago.payment_id }}
												</v-btn>

												<span v-if="pago.payment_type_id != 'RETENCION_EMITIDA'">
													{{ pago.payment_id }}
												</span>
											</td>
											<td>{{ pago.payment_type }}</td>
											<td>{{ pago.payment_ref_num }}</td>
											<td>{{ pago.effective_date.substring(0, 10) }}</td>
											<td>${{ pago.amount_applied }}</td>
										</tr>
									</tbody>
									<tbody v-else>
										<tr>
											<td colspan="5" class="text-center">
												<v-icon>mdi-alert</v-icon> Sin pagos realizados
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
					<v-row>
						<v-col md="12" sm="12">
							<v-alert dense text icon="mdi-format-list-checks" light color="blue-grey darken-2">
								Items de la facutra
							</v-alert>
							<v-data-table :headers="headItems" :items="itemsFactura" class="elevation-1">
								<template v-slot:item.valorUnitario="{ item }">
									{{ Math.abs(item.valorUnitario) }}
								</template>
								<template v-slot:item.total="{ item }">
									{{ Math.abs(item.total) }}
								</template>
								<template v-slot:footer>
									<v-row class="mt-3">
										<v-col cols=12 md="9">
											<v-expansion-panels accordion v-model="panel">
												<v-expansion-panel class='px-0 pb-0'>
													<v-expansion-panel-header color="blue-grey lighten-5">
														ASIENTOS CONTABLES
													</v-expansion-panel-header>
													<v-expansion-panel-content>
														<AccountingEntries :asientos=asientos />
													</v-expansion-panel-content>
												</v-expansion-panel>
											</v-expansion-panels>
										</v-col>
										<v-col cols=12 md=3>
											<v-alert dense text icon="mdi-cash-100" light color="blue-grey darken-2">
												Totales
											</v-alert>
											<v-simple-table>
												<template v-slot:default>
													<tbody>
														<tr>
															<td>Sub total</td>
															<td>${{ totalesFactura.subTotal }}</td>
														</tr>
														<tr>
															<td>Sub total IVA 12%</td>
															<td>${{ totalesFactura.subTotalConIva }}</td>
														</tr>
														<tr>
															<td>Sub total IVA 0%</td>
															<td>${{ totalesFactura.subTotalSinIva }}</td>
														</tr>
														<tr>
															<td>IVA</td>
															<td>${{ totalesFactura.totalIVA }}</td>
														</tr>
														<tr>
															<td>Total</td>
															<td><b>${{ totalesFactura.total }}</b></td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-col>
									</v-row>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import ExpandableFilters from '../general/ExpandableFilters'
import AccountingEntries from '../general/AccountingEntries'
import Vue from 'vue'

export default {
	name: "FacturasRolComponent",
	components: {
		ExpandableFilters,
		AccountingEntries
	},
	data: () => ({
		headers: [
			{ text: "ID", value: "invoice_id" },
			{ text: "Numero", value: "numero_factura" },
			{ text: "Fecha", value: "invoice_date" },
			{ text: "Tipo", value: "tipo_factura" },
			{ text: "Estado", value: "estado" },
			{ text: "Empleado", value: "empleado" },
			{ text: "Total", value: "total" },
			{ text: "Saldo", value: "saldo" },
			{ text: "Opciones", value: "opcion" },
		],
		lista: [],
		options: {},
		dialog: false,
		notifications: false,
		sound: true,
		widgets: true,
		panel: '',
		cabecera: {
			invoice_date: "",
			cliente: "",
			estado: "",
			invoice_type_id: "",
			status_id: ""

		},
		numeroFactura: {
			establecimiento: null,
			ptoEmision: null,
			numero: null,
			claveAcceso: null
		},
		itemsEstado: [
			{ estadoId: "INVOICE_READY", estadoNombre: "Listo" },
			{ estadoId: "INVOICE_PAID", estadoNombre: "Pagado" },
			{ estadoId: "INVOICE_IN_PROCESS", estadoNombre: "En proceso" },
			{ estadoId: "INVOICE_CANCELLED", estadoNombre: "Canceladas" }
		],
		pagos: [],
		asientos: [],
		itemsFactura: [],
		totalesFactura: [],
		headItems: [
			{ text: "No.", value: "secId", align: "start" },
			{ text: "Descripcion", value: "description", align: "start" },
			{ text: "Cantidad", value: "cantidad", align: "end" },
			{ text: "Precio", value: "valorUnitario", align: "end" },
			{ text: "%IVA", value: "pct_iva", align: "end" },
			{ text: "IVA", value: "valor_iva", align: "end" },
			{ text: "Total", value: "total", align: "end" },
		],
		filters: [
			{
				cols: 6,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
				v_model: '',
				label: 'Número',
				type: 'input_text'
			},
			{
				cols: 6,
				class: 'py-0 py-md-2 col-md-3 pl-0 pl-sm-2',
				v_model: '',
				label: 'Empleado',
				type: 'input_text'
			},
			{
				cols: 12,
				class: 'py-0 py-md-2 col-md-3 pr-0 pl-0 pr-sm-2 mb-1',
				v_model: [],
				label: 'Fecha',
				type: 'input_date_time',
				clearable: true,
			},
			{
				cols: 6,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
				v_model: '',
				label: 'Tipo',
				type: 'input_select',
				text: 'name',
				value: 'invoice_type_id',
				clearable: true,
				multiple: true,
				items: [
					{ 'invoice_type_id': 'NV_HONORARIOS', 'name': 'Nota de venta' },
					{ 'invoice_type_id': 'INVOICE_HONORARIOS', 'name': 'Factura honorarios' },
					{ 'invoice_type_id': 'PAYROL_INVOICE', 'name': 'Factura relación' },
				]
			},
			{
				cols: 6,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
				v_model: '',
				label: 'Estado',
				type: 'input_select',
				text: 'name',
				value: 'status_id',
				clearable: true,
				items: [
					{ 'status_id': 'INVOICE_READY', 'name': 'Listo' },
					{ 'status_id': 'INVOICE_PAID', 'name': 'Pagado' },
					{ 'status_id': 'INVOICE_IN_PROCESS', 'name': 'En proceso' },
					{ 'status_id': "INVOICE_CANCELLED", 'name': "Canceladas" }
				],
			},
		],
		currentPage: 1,
		pageCount: 1,
		itemsPerPage: 20,
		searchNumber: "",
		searchFechaInv: "",
		searchEstado: "",
		searchCliente: "",
		required: [
			v => !!v || 'El campo es requerido'
		]
	}),
	watch: {
		options: {
			handler() {
				this.cargarLista(false)
			},
			deep: true
		}
	},
	computed: {
		...mapState("master", ["loadingTable", "user", "tenantId", "paramAlertQuestion"]),

		...mapGetters("access", ["btnAbrirCaja"]),

		updateHeader() {
			return (this.cabecera.invoice_type_id == 'PAYROL_INVOICE' || (this.cabecera.invoice_type_id == 'INVOICE_HONORARIOS' && this.cabecera.status_id == 'INVOICE_READY'))
		},

		saldo() {
			let pagado = 0
			this.pagos.forEach((obj) => {

				pagado += parseFloat(obj.amount_applied.replace(',', ''))
			})

			if (typeof this.totalesFactura.total != 'undefined')
				this.totalesFactura.total = this.totalesFactura.total.replace(',', '')

			return parseFloat(this.totalesFactura.total - pagado).toFixed(2)
		}
	},
	methods: {

		...mapMutations("master", ["setUrl", "setOverlay", "setMenu", "setLoadingTable", "setTitleToolbar"]),

		...mapActions("master", ["requestApi", "alertNotification"]),

		cargarLista(filtrar) {

			if (filtrar) this.currentPage = 1;
			this.lista = []
			this.setLoadingTable(true);
			this.setUrl("invoice");
			this.requestApi({
				method: "GET",
				data: {
					invoiceType: this.filters[3].v_model,
					page: this.currentPage,
					page_count: this.pageCount,
					page_size: this.itemsPerPage,
					empleado: this.filters[1].v_model,
					invoiceNumber: this.filters[0].v_model,
					statusId: this.filters[4].v_model,
					invoiceDate: this.filters[2].v_model
				},
			}).then(res => {
				console.log(res);
				this.lista = res.data._embedded.invoice[0].invoice;
				this.pageCount = res.data._embedded.invoice[0].page_count;
				this.setLoadingTable(false);
			});
		},

		formInvoice(invid) {
			this.setUrl("invoice/" + invid);
			this.requestApi({
				method: "GET",
				data: {},
			}).then(res => {

				this.asientos = res.data.asientos.sort((a, b) => { return a.acctg_trans_entry_seq_id - b.acctg_trans_entry_seq_id })
				this.cabecera = res.data.cabecera;
				this.itemsFactura = res.data.itemsFactura;
				this.totalesFactura = res.data.totales;
				this.pagos = res.data.pagos
				this.numeroFactura.numero = res.data.cabecera.invoice_number
				this.numeroFactura.ptoEmision = res.data.cabecera.codigo_punto_emision
				this.numeroFactura.establecimiento = res.data.cabecera.codigo_establecimiento
				this.numeroFactura.claveAcceso = res.data.cabecera.autorizacion_sri
				this.dialog = true

			});
		},

		actualizarCabecera() {

			if (!this.$refs.form_cabecera.validate())
				return
			console.log(this.cabecera);

			this.setUrl("invoice");
			this.requestApi({
				method: "POST",
				data: {
					typeAction: 'updateHeaderInvoiceRol',
					invoiceId: this.cabecera.invoice_id,
					invoiceType: this.cabecera.invoice_type_id,
					invocieDate: this.cabecera.inv_fecha,
					...this.numeroFactura
				}
			}).then(res => {

				console.log(res)
				this.cargarLista(true)
				this.close()
				this.formInvoice(this.cabecera.invoice_id)
				this.alertNotification({
					param: {
						html: res.data.res.msg
					}
				})


			});

		},

		actualizaEstadoInvoice(invoice_id) {

			Vue.swal({
				html: "¿Está seguro de cancelar esta factura de rol?",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Aceptar',
				cancelButtonText: 'Cerrar',
				...this.paramAlertQuestion
			}).then(result => {

				if (result.isConfirmed) {

					this.setUrl("invoice");
					this.requestApi({
						method: "PATCH",
						data: {
							invoiceId: invoice_id,
							invoiceType: 'CANCELAR_INVOICE'
						},
					}).then(res => {
						console.log(res)
						this.cargarLista(true)
						this.alertNotification({
							param: {
								html: res.data.detail.msg
							}
						})

					});

				}

			})

		},

		close() {
			Object.assign(this.numeroFactura, {
				establecimiento: null,
				ptoEmision: null,
				numero: null
			});
			this.dialog = false
		},

		handlePageChange(value) {
			this.currentPage = value
			this.cargarLista(false);
		}

	},
	mounted() {
		this.setTitleToolbar('FACTURAS DE NÓMINA')
		this.cargarLista(false);
	},
}
</script>